import axios from "axios";
import {useEffect, useState} from "react";

axios.defaults.withCredentials = true;

export function useApiList(listName: string){
    const [ returnPayload, setReturnPayload ] = useState<any>();
    useEffect(() => {
        get(`/?route=util&action=${listName}`)
            .then((response: any) => {
                //setLoading(false);
                if(response.status == 200) {
                    const list = Object.keys(response.data).map((key) => {
                        return { id: key, title: response.data[key] };
                    })
                    setReturnPayload(list);
                }
            })
            .catch((err) => {
                //setLoading(false);
            })
    }, [ listName ]);

    return returnPayload;
}


export function post(endpoint: string, data: any) {
    let formData = convertModelToFormData(data);
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': `multipart/form-data`,
        },
        withCredentials: true,
        data: formData
    })
}

export function get(endpoint: string) {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        withCredentials: true
    })
}

export function getExtruturaList(){
    return {
        131: 'Telha Colonial Gancho',
        120: 'Telha Ondulada / Fibrocimento',
        143: 'Telha Metalica Perfil 55CM',
        133: 'Solo',
        132: 'Sem estrutura',
        141: 'Parafuro Estrutural Madeira',
        156: 'Parafuro Estrutural Ferro',
        140: 'Laje',
        296: 'Telha Metalica Zipada',
    }
}

export function getOrcamentoTipoCalculoList(){
    return {
        1: 'Geração, Consumo e Venda',
        2: 'Geração e Venda',
    }
}

/**
 * @param model
 * @param form
 * @param namespace
 * @return FormData
 */
export function convertModelToFormData(model: any, form: FormData|null = null, namespace = ''): FormData {
    let formData = form || new FormData();

    for (let propertyName in model) {
        if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
        let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
        if (model[propertyName] instanceof Date)
            formData.append(formKey, model[propertyName].toISOString());
        else if (model[propertyName] instanceof Array) {
            model[propertyName].forEach((element: any, index: any) => {
                const tempFormKey = `${formKey}[${index}]`;
                convertModelToFormData(element, formData, tempFormKey);
            });
        }
        else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
            convertModelToFormData(model[propertyName], formData, formKey);
        else if (model[propertyName] instanceof File)
            formData.append(formKey, model[propertyName]);
        else
            formData.append(formKey, model[propertyName].toString());
    }
    return formData;
}
export const normalizePhoneNumber = (value: String | undefined) => {
    if (!value) return ''

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)/, '$1')
}

export const normalizeCnpjNumber = (value: String | undefined) => {
    if (!value) return ''

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export const normalizeCepNumber = (value: String | undefined) => {
    if (!value) return ''
    return value.replace(/\D/g, "")
        .replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
        .replace(/(-\d{3})(\d+?)/, '$1')
}

export const normalizeCpfCnpjNumber = (value: string | undefined) => {
    if (!value) return ''

    const cleanValue = value.replace(/\D/g, '')

    if (cleanValue.length <= 11) {
        // Apply CPF mask
        return cleanValue
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    } else {
        // Apply CNPJ mask
        return cleanValue
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }
}

export const normalizeHumanDate = (value: string | undefined) => {
    if (!value) return ''
    return value.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d+?)/, '$1')
}

export const normalizeToUpperCase = (value: string | undefined) => {
    if (!value) return ''
    return value.toUpperCase()
}

export const normalizeToLowerCase = (value: string | undefined) => {
    if (!value) return ''
    return value.toLowerCase()
}
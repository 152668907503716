import React, {useContext} from 'react'
import {useFormContext} from "react-hook-form";

export default function UiInputList({ label, name, size, idField, titleField, list, ...rest }: any){
    const { register } = useFormContext();

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label">{label}</label>
                <div className="field-container">
                    <select className="form-control" {...register(name)} {...rest}>
                        { list && list.map((item: any, index: any) => <option key={index} value={item[idField]}>{item[titleField]}</option> )}
                    </select>
                </div>
            </div>
        </div>
    )
}
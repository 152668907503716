import React, {useState, useEffect} from 'react';
import {useFormContext} from "react-hook-form";

import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

// @ts-ignore
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import {FilePondFile, setOptions} from 'filepond';
// @ts-ignore
import ptBR from 'filepond/locale/pt-br.js';
setOptions(ptBR);

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export default function UiFotoUpload({ name }: any){
    const formContext = useFormContext();
    const { register } = formContext;
    const value = formContext.watch(name);

    const [ files, setFiles ] = useState<any>(value);

    function updateFormFromFileList(fileItems: FilePondFile[]){
        formContext.setValue(name, fileItems.map(fileItem => {
            return  {
                source: fileItem.serverId,
                options: {
                    type: 'local',
                    // file: {
                    //     name: fileItem.filename,
                    //     size: fileItem.fileSize,
                    //     type: fileItem.fileType,
                    // }
                },
            };
        }))
    }

    useEffect(() => {
        register(name);
    }, []);

    useEffect(() => {
        console.log('Atualizou o files', files);
        if(files) {
            updateFormFromFileList(files);
        }
    }, [ files ])

    return <FilePond
            files={files}
            onupdatefiles={setFiles}
            onprocessfiles={() => updateFormFromFileList(files)}
            name="files"
            allowMultiple
            labelIdle='Solte aqui os arquivos ou <span class="filepond--label-action">Selecione</span>'
            server={{
                process: {
                    withCredentials: true,
                    url: `${process.env.REACT_APP_API_ENDPOINT}/?route=contrato/form&action=fotoUpload`,
                    ondata: (formData) => { // Before sending the files to the server, append size of the current file and the order of the file; order to delete all previous files if the current file is the first, even though the session ID didn't change, and size to validate against total size of files uploaded.
                        formData.append("cont_id", '280');
                        return formData;
                    },
                    onload: (response) => {
                        const jsonResponse = JSON.parse(response);
                        return jsonResponse.payload;
                    },
                    onerror: (response) => {
                        return response;
                    }
                },
                revert: {
                    withCredentials: true,
                    url: `${process.env.REACT_APP_API_ENDPOINT}/?route=contrato/form&action=fotoUpload`,
                    onload: (response) => { // Update value through onChange once DELETE request sent.
                        return response;
                    }
                },
                load: {
                    withCredentials: true,
                    url: `${process.env.REACT_APP_API_ENDPOINT}/?route=contrato/form&action=fotoLoad&load=`,
                    onload: (response) => { // Update value through onChange once DELETE request sent.
                        return response;
                    }
                }
            }}
        />;

        // <FilePond
        //     files={value}
        //     onupdatefiles={(selectedFiles) => {
        //         const fileMap = selectedFiles.map((fileItem: any) => {
        //             return {
        //                 ...fileItem,
        //                 serverId: fileItem.serverId,
        //             }
        //         });
        //
        //         console.log('onupdatefiles', fileMap);
        //
        //         formContext.setValue(name, fileMap);
        //     }}
        //     allowMultiple={true}
        //     allowReorder={false}
        //     server={{
        //         url: `${process.env.REACT_APP_API_ENDPOINT}/?route=contrato/form&action=fotoUpload`,
        //         process: {
        //             url: `${process.env.REACT_APP_API_ENDPOINT}/?route=contrato/form&action=fotoUpload`,
        //             method: "POST",
        //             withCredentials: false,
        //             headers: {},
        //             onload: (response) => {
        //                 // Converter a resposta em JSON e armazená-la no estado
        //                 const jsonResponse = JSON.parse(response);
        //
        //                 console.log({ jsonResponse });
        //
        //                 return jsonResponse.payload;
        //             },
        //             onerror: (response) => console.log(response),
        //             ondata: (formData) => {
        //                 formData.append("cont_id", '280');
        //                 return formData;
        //             },
        //         },
        //     }}
        //     name="files"
        //     labelIdle='Solte aqui os arquivos ou <span class="filepond--label-action">Selecione</span>'
        // />;

}
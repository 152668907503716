import React, {useContext, useEffect, useState} from 'react';
import {Util} from "../util/Util";
import {get} from "../services/ApiService";
import {useFormContext} from "react-hook-form";
import AuthContext, {AuthProvider} from "../services/AuthProvider";
import UiInputNumber from "../components/Form/UiInputNumber";
import ReactSlider from 'react-slider'

export function useCustoDetalhado(props: any){
    const [ returnPayload, setReturnPayload ] = useState<any>();
    const { precoVenda, descontoVendedor, data } = props;
    useEffect(() => {
        get(`/?route=orcamento/form&action=custoDetalhado&kit_id=${data.kit.id}&preco_venda=${precoVenda}&desconto_vendedor=${descontoVendedor}`) // &payload=${JSON.stringify(props)}
            .then((response: any) => {
                //setLoading(false);
                if(response.status == 200) {
                    setReturnPayload(response.data.payload);
                }
            })
            .catch((err) => {
                //setLoading(false);
            })
    }, [ props.precoVenda, props.data.kit.id, descontoVendedor ]);

    return returnPayload;
}


export function useMediaPrecos(kitId: any){
    const [ returnPayload, setReturnPayload ] = useState<any>();
    useEffect(() => {
        get(`/?route=orcamento/form&action=mediaPrecos&kit_id=${kitId}`) // &payload=${JSON.stringify(props)}
            .then((response: any) => {
                //setLoading(false);
                if(response.status == 200) {
                    console.log(response.data.payload);
                    setReturnPayload(response.data.payload);
                }
            })
            .catch((err) => {
                //setLoading(false);
            })
    }, [ kitId ]);

    return returnPayload;
}


export function MediaPrecos({ custoDetalhado, kit, precoVenda, descontoVendedor, consumoMedio, data }: any){
    const mediaPrecos = useMediaPrecos(kit.id);
    const formContext = useFormContext();

    function getCellStyle(item: any) {
        let cor;
        let payload: any = {
            textAlign: 'center'
        };

        if(item.percent >= 50){
            payload['fontWeight'] = 'bold';
        }

        if(item.preco < custoDetalhado.precoVendaMinimo){
            payload['backgroundColor'] = '#f8d7da';  // Vermelho suave
            payload['opacity'] = 0.5;
        }else{
            payload['cursor'] = 'pointer';
            payload['backgroundColor'] = '#d4edda';  // Verde suave
        }

        if(item.percent < 10){
            payload['opacity'] = 0.5;
            payload['color'] = '#CCC';
        }

        return payload;
    }

    return <>
        <table style={{marginBottom: 10}}>
            <thead>
                <tr>
                    <th style={{textAlign: 'center'}} colSpan={(mediaPrecos?.semanasList?.length + 1) ?? 1}>Histórico de Preços</th>
                </tr>
                <tr>
                    <th>Preços / Semanas</th>
                    {
                        mediaPrecos && mediaPrecos.semanasList && mediaPrecos.semanasList.map((semanaItem: any) => {
                            return  <th>{ semanaItem }</th>
                        })
                    }
                </tr>
            </thead>
            <tbody>
            {
                mediaPrecos && mediaPrecos.precosList && mediaPrecos.precosList.map((precoItem: any) => {
                    return <tr>
                        <td style={{textAlign: 'right'}}>{ Util.moeda(precoItem) }</td>
                        {
                            mediaPrecos.semanasList && mediaPrecos.semanasList.map((semanaItem: any) => {
                                const subItem = mediaPrecos.table[precoItem][semanaItem];
                                let color = '#CCC';
                                return <td onClick={() => formContext.setValue('precoVendaBase', precoItem)}
                                           style={getCellStyle(subItem)}>
                                    { subItem.total } - { subItem.percent.toFixed(2) }%
                                </td>;
                                //return <td>{JSON.stringify(subItem, null, 2)}</td>
                            })
                        }
                    </tr>
                })
            }
            </tbody>
        </table>
    </>;

    //return <><pre>{JSON.stringify(mediaPrecos, null, 2)}</pre></>;
}

export function CostDetail(props: any){

    const { kit, precoVenda, descontoVendedor, consumoMedio, data } = props;
    const { user } = useContext<any>(AuthContext);

    const { setValue } = useFormContext();

    const custoDetalhado = useCustoDetalhado({
        precoVenda,
        descontoVendedor,
        precoCusto: kit.preco,
        numeroPlacas: kit.numeroPlacas,
        potenciaPlacas: kit.potenciaPlacas,
        inversor: kit.inversor,
        data,
        acesso: user.acesso
    });

    if(!custoDetalhado){
        return <>Carregando</>;
    }

    const setMinimalValue = () => {
        setValue('precoVendaBase', custoDetalhado.precoVendaMinimo);
    }

    const custoColor = (custoDetalhado.lucroLiquido < 8)?(custoDetalhado.lucroLiquido<5)?'#C00':'#009':'#090'
    //const custoColorReal = (custoDetalhado.lucroLiquido < 10)?(custoDetalhado.lucroLiquido<5)?'#C00':'#009':'#090'

    return <>
        <MediaPrecos custoDetalhado={custoDetalhado} {...props } />

        <table>
            <tbody>
            { user.acesso == 1 && custoDetalhado.custos.map((item: any, index: number) => {
                return item.show == true && <tr key={index}>
                    <th>{item.title}:</th>
                    <td style={{textAlign: 'right'}}>{Util.moeda(item.value)}</td>
                    <td style={{textAlign: 'center'}}>
                        {(item.percent).toFixed(2)}%

                        {/*<InputNumber
                            onChange={(e) => setValue(`custoDetalhado.${index}`, e.value)}
                            value={watch(`custoDetalhado.${index}`)}

                            suffix=" %"
                            locale="pt-BR"
                        />*/}
                    </td>
                </tr>
            }) }
            <tr>
                <th>Custo total:</th>
                <td style={{textAlign: 'right'}}>{Util.moeda(custoDetalhado.custoTotal)}</td>
                <td style={{textAlign: 'center'}}>{((custoDetalhado.custoTotal / precoVenda)*100).toFixed(2)}%</td>
            </tr>
            <tr>
                <th>Preço de Venda:</th>
                <td style={{textAlign: 'right'}}>{Util.moeda(precoVenda)}</td>
                <td style={{textAlign: 'center'}}>{((1 - (custoDetalhado.custoTotal / precoVenda))  * 100).toFixed(2)}%</td>
            </tr>

            {/*<tr style={{fontWeight: 'bold'}}>*/}
            {/*    <td style={{textAlign: 'right'}}>Lucro liquido:</td>*/}
            {/*    <td style={{textAlign: 'right'}}>{Util.moeda(precoVenda - custoDetalhado.custoTotal)}</td>*/}
            {/*    <td style={{textAlign: 'center', color: (custoDetalhado.lucroLiquido < 5)?'#C00':'#009'}}>{custoDetalhado.lucroLiquido.toFixed(2)}%</td>*/}
            {/*</tr>*/}

            { user.acesso == 1 && <tr>
                <th>Margem:</th>
                <td style={{textAlign: 'right'}}>{Util.moeda(precoVenda - custoDetalhado.custoTotal)}</td>
                <td colSpan={2} style={{textAlign: 'center', color: custoColor, fontSize: 14}} title={Util.moeda(precoVenda - custoDetalhado.custoTotal)}>{custoDetalhado.lucroLiquido.toFixed(2)}%</td>
            </tr> }
            <tr>
                <th>Venda mínimo:</th>
                <td style={{textAlign: 'center'}} colSpan={2} onClick={setMinimalValue}>{Util.moeda(custoDetalhado.precoVendaMinimo)}</td>
            </tr>
            </tbody>
        </table>
    </>

}

export function KitDetail(props: any) {
    const { kit, data, precoVenda, consumoMedio } = props;
    const { user } = useContext<any>(AuthContext);

    if(!kit.numeroPlacas){
        kit.referencia = kit.kit_referencia;
        kit.numeroPlacas = kit.kit_numero_placas;
        kit.inversor = kit.kit_inversor;
        kit.estrutura = kit.kit_estrutura;
        kit.link = kit.kit_link;
        kit.imagem = kit.kit_imagem;
        kit.potenciaPlacas = kit.kit_potencia_placas;
        kit.preco = kit.kit_preco;
        kit.kwp = kit.kit_kwp;
        kit.geracaoGarantida = kit.geracao_garantida;
    }else{
        let incidenciaSolar = 3.36;
        if(data.incidenciaSolar && data.incidenciaSolar != ''){
            incidenciaSolar = data.incidenciaSolar;
        }
        kit.geracaoGarantida = kit.numeroPlacas * Math.round((kit.potenciaPlacas * incidenciaSolar * 30) / 1000)
    }

    if(!Array.isArray(kit.product_list)){
        kit.product_list = JSON.parse(kit.product_list);
    }

    // const estruturaList: any = getExtruturaList();
    // const folgaPlacas = Math.floor((kit.inversor * 1.3 * 1000) / kit.potenciaPlacas) - kit.numeroPlacas;

    return <>
        <div className="kit-detail">
            <div className="kit-detail-image">

                <a target="_blank" href={kit.link}><img src={kit.imagem} alt={kit.nome} /></a>
                <div className="kit-title">
                    <table>
                        <tr>
                            <th>Qtd</th>
                            <th>Produto</th>
                        </tr>
                        { kit.product_list.map((product: any) => {
                            return <tr>
                                <td style={{textAlign: 'center'}}>{product.qtd}</td>
                                <td>{product.nome}</td>
                            </tr>
                        }) }
                    </table>

                    <br />

                    <table>
                        <tr>
                            <th>Referencia</th>
                            <td>{kit.referencia}</td>
                        </tr>
                        <tr>
                            <th>Potencia</th>
                            <td>{kit.kwp} kWp</td>
                        </tr>
                        { user.acesso == 1 && <tr>
                            <th>Custo</th>
                            <td>{Util.moeda(kit.preco)}</td>
                        </tr> }
                        <tr>
                            <th>Geração estimada</th>
                            <td>{kit.geracaoGarantida} kWh/mês - {(((kit.geracaoGarantida / consumoMedio))  * 100).toFixed(2)}% do consumo</td>
                        </tr>
                        { user.acesso == 1 && <tr>
                            <th>Preço por kWh</th>
                            <td>{Util.moeda(precoVenda / kit.geracaoGarantida)}/kWh</td>
                        </tr> }

                    </table>

                    {/*<br />*/}
                    {/*{kit.referencia}<br />*/}
                    {/*<b></b><br />*/}
                    {/*{kit.numeroPlacas} x {kit.potenciaPlacas}W - Inversor: {kit.inversor} kW<br />*/}
                    {/*{estruturaList[kit.estrutura] ?? kit.estrutura}<br />*/}
                    {/*Folga para <b>{folgaPlacas}</b> placas<br />*/}

                    {/*{ user.acesso == 1 && <label className="simple-checkbox">*/}
                    {/*    <input type="checkbox" { ...register('showCosts') } />*/}
                    {/*    <span>Mostrar custos</span>*/}
                    {/*</label> }*/}
                    {/*Engenheiro:*/}
                    {/*<select className="form-control" {...register('engenheiro')}>*/}
                    {/*    { engenheiroTabelaList.map((item: any, index: any) => <option key={index} value={index}>{item.name}</option> )}*/}
                    {/*</select>*/}
                </div>
            </div>
            <div className="kit-detail-text">

                <CostDetail { ...props } />

            </div>
        </div>
    </>;
}
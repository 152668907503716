import React from 'react';
import { useFormContext } from "react-hook-form";
import {Util} from "../../util/Util";
import CheckIcon from '@rsuite/icons/Check';
import WaitIcon from '@rsuite/icons/Wait';
import cn from "classnames";

interface FileInputProps {
    label: string;
    name: string;
    size?: number;
    value?: any; // A propriedade value representando o arquivo já enviado
    linkComponent?: React.ElementType;
}

export default function UIInputFile({ label, name, size, ...rest }: FileInputProps) {
    const formContext = useFormContext();
    const { register } = formContext;
    const LinkComponent = rest.linkComponent as React.ElementType;

    const value = formContext.watch(name);

    console.log({ name, value });

    // Verifica se há um arquivo existente
    const isFileUploaded = value && value.fileurl;

    const isFileSelected = value && value.length > 0 && value[0]?.name;

    return (
        <div className={cn(`col-sm-${size ? size : 12} ui-input-file`, {
            'with-file-selected': value,
            'is-file-uploaded': isFileUploaded,
            'is-file-selected': isFileSelected,
        })}>
            <div className="form-item">
                <label className="form-label">{label}
                    {LinkComponent && (
                        // @ts-ignore
                        <LinkComponent formContext={formContext} name={name} />
                    )}
                </label>

                <div className="field-container">

                    <button>
                        Selecionar arquivo
                        <input
                            type="file"
                            className="form-control"
                            {...register(name)}
                            {...rest}
                        />
                    </button>

                    <div className="file-info">
                        {isFileUploaded && (
                            <>
                                <CheckIcon />
                                <a className={"file-name"} href={value.fileurl} target="_blank" rel="noopener noreferrer">
                                    {value.new_name || value.name}
                                </a>
                                <div className="file-size">({Util.humanFileSize(value.size)})</div>
                            </>
                        )}
                        {!isFileUploaded && !isFileSelected && (
                            <>
                                <div className="file-label">Nenhum arquivo selecionado</div>
                            </>
                        )}
                        {!isFileUploaded && isFileSelected && (
                            <>
                                <WaitIcon />
                                <div className={"file-name"} title="Upload pendente">{value[0].name}</div>
                                <div className="file-size">({Util.humanFileSize(value[0].size)})</div>
                            </>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}

import React from 'react';

import Chart from "react-apexcharts";
import {formatter} from "./DashboardUtil";

const LineChart = ({ group }) => {

    let newData = [];
    group.data.map(item => {
        newData.push([ item.date, item.value ]);
    });

    const config = {
        series: [{
            name: group.title,
            data: newData
        }],
        options: {
            chart: {
                type: 'area',
                stacked: false,
                height: '100%',
                animations: {
                    enabled: false
                },
                zoom: {
                    type: 'x',
                    enabled: false,
                    autoScaleYaxis: true
                },
                toolbar: {
                    tools: {
                        download: false
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            // title: {
            //     text: group.title,
            //     align: 'center',
            // },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (val) => formatter(val, group.yFormatter)
                },
                title: {
                    // text: 'Price'
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: (val) => formatter(val, group.xFormatter),
                    datetimeUTC: false
                },
            },
            tooltip: {
                shared: true,
                y: {
                    formatter: (val) => formatter(val, group.yFormatter)
                }
            }
        },
    };

    return  <Chart
        options={config.options}
        series={config.series}
        type="area"
        width="100%"
        height="100%"
    />
}

export default LineChart;
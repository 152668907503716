import React, { useState } from 'react';
import classNames from "classnames";

// Componente Tab, apenas para encapsular o conteúdo
const Tab = ({ children }: any) => {
    return <div>{children}</div>;
};

// Componente UiTabs que vai gerenciar as abas
const UiTabs = ({ children }: any) => {
    const [activeTab, setActiveTab] = useState(0); // Controle da aba ativa

    return (
        <div className="ui-tabs">
            {/* Renderiza os títulos das abas */}
            <nav className="ui-tabs-nav">
                {React.Children.map(children, (child, index) => (
                    child && <div
                        className={classNames('ui-tab-nav-item', {
                            'active': index == activeTab
                        })}
                        onClick={() => setActiveTab(index)}
                    >
                        {child.props.title}
                    </div>
                ))}
            </nav>
            <div className="ui-tabs-content">
                {children[activeTab]}
            </div>
        </div>
    );
};

export { UiTabs, Tab };